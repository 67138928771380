import { ActionFavoriteButton, ListViewRenderProps } from '@portals/core-immobilien/src/components';
import React from 'react';

import { EstateLink } from '../EstateLink/EstateLink';

export const renderBookmarkButton: ListViewRenderProps['renderBookmarkButton'] = (estateId, size) => (
  <ActionFavoriteButton id={estateId} size={size} />
);

export const renderEstateLink: ListViewRenderProps['renderEstateLink'] = (estateId, children) => (
  <EstateLink id={estateId} key={estateId}>
    {children}
  </EstateLink>
);
