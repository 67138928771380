import { EstateSearch } from '@portals/core-immobilien/src/components';
import { EstateSearchProps } from '@portals/core-immobilien/src/components/EstateSearch/EstateSearch.types';
import React from 'react';

import { useEstateSearchHandler } from './useEstateSearchHandler/useEstateSearchHandler';

export const EstateSearchWrapper: React.FC<EstateSearchProps> = (props) => {
  const { handleEstateSearch } = useEstateSearchHandler();
  return <EstateSearch {...props} isSkeletonEnabled={true} updateEstateSearchHandler={handleEstateSearch} />;
};

EstateSearchWrapper.displayName = 'EstateSearchWrapper';
