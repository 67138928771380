import { Option } from '@portals/core-immobilien/src/config';

export enum EstateAdFlowPath {
  HOUSE = '/inserieren/es-haus-inserieren-verkauf',
  FLAT = '/inserieren/es-wohnung-inserieren-verkauf',
  PROPERTY = '/inserieren/es-grundstueck-inserieren-verkauf',
}

export const getAllEstateAdFlowPaths = (t: (a: string) => string): Option[] => {
  return [
    {
      value: EstateAdFlowPath.HOUSE,
      label: t('objectTypes.house'),
    },
    {
      value: EstateAdFlowPath.FLAT,
      label: t('objectTypes.flat'),
    },
    {
      value: EstateAdFlowPath.PROPERTY,
      label: t('objectTypes.property'),
    },
  ];
};

export default EstateAdFlowPath;
